.video {
	display: block;
	position: relative;

	.video-container {
		width: 100%;
		height: 392px;
		position: relative;
		cursor: pointer;
		background-image: url('/assets/img/general/video_bg.png');
		background-size: cover;
		background-position: center center;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50px;
			width: 75px;
			height: 75px;
			border: 2px solid #fff;
			transition: opacity 0.1s;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-40%, -50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 16px 0 16px 30px;
			border-color: transparent transparent transparent #fff;
			transition: opacity 0.1s;
		}
	}

	&-iframe {
		position: relative;
  		padding-bottom: 56.25%;
		margin: 20px auto 40px;
		max-width: 724px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.video-modal {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(239, 239, 239, .95);
	z-index: 99999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 25px;

	.video-modal-container {
		max-width: 760px;
		margin: 0 auto;

		video {
			width: 100%;
			height: 100%;
		}
	}

	.video--close {
		position: absolute;
		right: 20px;
		top: 20px;
		font-weight: 700;
		cursor: pointer;
	}
}
