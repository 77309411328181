.container {
    &-link {
        font-weight: 500;
        color: white;
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
        text-decoration: none;
        background: rgba(0, 0, 0, 0.5);
        border: 0;
        border-radius: 50px;
        text-decoration: none;
        font-size: 12px;
        padding: 6px;

        @media (min-width: 768px) {
            right: 20px;
            bottom: 20px;
            font-size: 16px;
            padding: 10px;
        }
    }
}
.btn {
	display: inline-flex;
	@include padding($short: 16px 45px);
	@include margin($right: 20px);
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	// border-radius: 25px;
	background-color: $red;
	text-decoration: none;

	.layout-v2 & {
		background-color: $red;
	}

	&:last-of-type {
		@include margin($right: 0);
	}

	&--white {
		color: $red;
		background-color: white;
		:not(.layout-v2) & {
			color: $red;
			background-color: white;
		}
	}

	&--gray {
		background-color: $gray;
		:not(.layout-v2) & {
			background-color: $gray;
		}
	}

	&--center {
		margin: 0 auto!important;
	}

	&--bordeaux {
		background-color: $bordeauxV2;

		.layout-v2 & {
			background-color: $bordeauxV2;
		}
	}

	&--purple {
		background-color: $purple
	}

	&--submit {
		@include relative;
		border-radius: 0;
		height: 50px;
		padding: 12px 25px;
		cursor: pointer;
		&::after {
			content: '';
			@include absolute(top 0 left 0);
			@include size(50px);
			background: url('/assets/img/icons/ico-arrow.svg') no-repeat center / cover;
		}
	}
	&--disabled {
		background-color: $gray-light;
	}
}
