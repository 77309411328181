.registration {
	padding: 40px;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;
	// border-radius: 20px;

	h2 {
		font-family: $nunito;
		font-weight: 800;
		color: $black-800;
		font-size: 31px;
		padding-bottom: 20px;
	}

	p {
		font-family: $nunito;
		font-weight: normal;
		font-size: 15px;
		line-height: 1.2;
		padding: 0 0 20px;
	}

	.search-block {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 25px;

		.left {
			position: relative;
			display: block;
			float: left;
			width: 48%;

			select {
				position: relative;
				width: 90%;
				background-color: $red;
				color: white;
				padding: 10px;

				option {
					background-color: #efefef;
					color: #8e8e8e;
				}
			}
		}

		.middle {
			position: relative;
			display: block;
			float: left;
			width: 4%;
			text-align: center;
			padding: 10px;

			p {
				color: #8e8e8e;
			}
		}

		.form__search {
			input {
				border: 1px solid #8e8e8e;
				position: relative;
				width: 100%;
				padding: 10px;
				color: $black-800;
				// border-radius: 10px;
			}

			button {
				font-size: 14px;
				background: transparent;
				border: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 14px;
				right: 10px;

				i {
					color: $black-800;
				}
			}
		}
	}

	.results {
		position: relative;
		display: none;
		width: 100%;
		margin-bottom: 25px;


		&.listing {
			display: block;

			p {
				width: 100%
			}
		}

		ul {
			li {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 10px;
				// border-radius: 10px;
				overflow: hidden;

				p {
					position: relative;
					display: inline-block;
					width: 80%;
					background-color: #efefef;
					padding: 10px;
					font-weight: 100;
					font-size: 12px;
					vertical-align: top;

					&.full-width {
						width: 100%
					}
				}

				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20%;
					background-color: $red-500;
					padding: 9px;
					text-decoration: none;
					color: white;
					text-align: center;
				}
			}
		}
	}

	.table-user-data {
		background: $black-100;
		overflow: hidden;
		padding: 1px;
		position: relative;

		.input-wrapper {
			float: left;
			padding: 1px;
			width: 25%;

			.container {
				background: $black-200;
				// border-radius: 4px;
				display: table;
				padding: 10px 16px;
				width: 100%;

				label {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					vertical-align: top;
					width: 1px;
					display: table-cell;
					margin: 0;
					padding: 0;
					white-space: nowrap;
				}

				input {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
					background: none;
					border: 0;
					padding: 0;
					margin: 0 4px 0;
					width: 100%;
					display: table-cell;
					margin: 0;
					overflow: hidden;
					padding: 0;
					vertical-align: top;
				}

				textarea {
					max-height: 160px;
					overflow-x: hidden;
					overflow-wrap: break-word;
					resize: none;
					height: 48px;
					width: 100%;
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 14px;
					line-height: 16px;
					background: none;
					border: 0;
					padding: 0;
					margin: 0 4px 0;
				}

				&.editable {
					background: #fff;
				}
			}

			&:first-child,
			&:nth-of-type(6n+7) {
				width: 75%;
			}
		}

		.observations {
			display: block;
		}
	}

	.btn {
		padding: 0;
		text-align: center;
		margin-top: 40px;

		&__submit {
			font-family: Gotham, sans-serif;
			font-weight: 500;
			background: $red-500;
			border: 0;
			// border-radius: 50px;
			color: #ffffff;
			font-size: 14px;
			padding: 16px 40px;
			cursor: pointer;
			text-decoration: none;
			text-transform: uppercase;
			// margin: 0 10px;
		}
	}

	.confirmation {
		display: none;

		p {
			padding: 0 0 10px 0;
		}
	}

	.validation-message {
		clear: both
	}
}
