.shortcuts {
    position: relative;
    width: 100%;
    display: block;
    ul {  
        display: flex;
        // height: 280px;
        background: $red-800;
        padding-left: 0;
		// border-radius: 20px;
		overflow: hidden;

		.layout-v2 & {
			color: $red;
			border-radius: 0;
		}

        li {
          list-style: none;
          flex: 0 33.33%;
          cursor: pointer;
          &:hover {
            background: #8a0f34;

			.layout-v2 & {
				background: #cc0001;
			}
          }

          &:nth-of-type(2) {
            background: $red-500;
          }

          &:last-of-type {
            background: $red-600;
          }

          a {
			height: 100%;
            padding-block: 20px;
			flex-direction: column;
			display: flex;
			align-items: center;
			justify-content: center;
              text-align: center;
              text-decoration: none;
              i {
                color: white;
                font-size: 50px;
                margin-bottom: 20px;
                }
                p {
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }
}
