@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) { //not being used in this project
    @each $property in $properties {
        #{$property}: $min-value;
    }
    @media (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }
    @media (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}
@mixin border-radius($key) {
    border-radius: $key;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}
@mixin box-shadow($key) {
    box-shadow: $key;
}
@mixin padding($top: null, $right: null, $bottom: null, $left: null, $short: null) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bottom;
    padding-left: $left;
    padding: $short;
}
@mixin margin($top: null, $right: null, $bottom: null, $left: null, $short: null) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bottom;
    margin-left: $left;
    margin: $short;
}
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i+1 <=length($args) and type-of(nth($args, $i + 1))==number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}
// Positioning helpers
@mixin absolute($args: '') {
    @include position(absolute, $args);
}
@mixin fixed($args: '') {
    @include position(fixed, $args);
}
@mixin relative($args: '') {
    @include position(relative, $args);
}
@mixin static($args: '') {
    @include position(static, $args);
}
@mixin portrait() {
    @media (orientation: portrait) {
        @content;
    }
}
@mixin landscape() {
    @media (orientation: landscape) {
        @content;
    }
}
@mixin bp($breakpoint, $type: min) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        $current-breakpoint: $breakpoint !global;
        @if $type==max {
            $value: $value - 0.063em
        }
        @media (#{$type}-width: $value) {
            @content;
        }
        $current-breakpoint: $default-breakpoint !global;
    }
    @else {
        @warn "Invalid breakpoint `#{$breakpoint}`.";
    }
}
@mixin placeholder($name) {
    // If placeholder doesn't exist yet in `$placeholders` list
    @if not index($placeholders, $name) {
        // Store its name
        $placeholders: append($placeholders, $name) !global;
        //Dumping a placeholder out of any media query as well
        // so basically at root level
        %#{$name}-#{$default-breakpoint} {
            @content;
        }
        // At root level
        @at-root {
            // Looping through `$breakpoints`
            @each $breakpoint,
            $value in $breakpoints {
                // Opening a media query block
                @media (min-width: $value) {
                    // Generating a placeholder
                    // Called $name-$breakpoint 
                    %#{$name}-#{$breakpoint} {
                        @content;
                    }
                }
            }
        }
    }
    // If placeholder already exists, just warn the user
    @else {
        @warn "Placeholder `#{$name}` already exists.";
    }
}
@mixin _($name) {
    @extend %#{$name}-#{$current-breakpoint} !optional;
}
@mixin orientation($args...) {
    @include portrait {
        @include bp($args...) {
            @content;
        }
    }
    @include landscape {
        @include bp($args...) {
            @content;
        }
    }
}
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}
@mixin z-index($key) {
    z-index: z-index($key);
}
@mixin keyframes($key) {
    @keyframes #{$key} {
        @content;
    }
}
@mixin animation($key...) {
    animation: $key;
}
@mixin transition($key...) {
    transition: $key;
}
// transformations :: rotate,scale, translate
@mixin transform($key...) {
    transform: $key;
}
// rotate
@mixin rotate($key) {
    @include transform(rotate(#{$key}deg));
}
// scale
@mixin scale($key) {
    @include transform(scale($key));
}
@mixin scale3d($key...) {
    @include transform(scale3d($key));
}
// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin font-size($font-size) {
    $base-size: strip-unit($base-font / 100);
    $base-px-size: 16 * $base-size;
    font-size: $font-size + px;
    font-size: (strip-unit($font-size) / $base-px-size) + rem;
}
@mixin font-tracking($value) {
    letter-spacing: ($value/1000) + em;
}
@mixin calc($property, $expression) {
    #{$property}: calc(#{$expression});
  }
// mixin for swiper pagination plugin - needs to be inside class .swiper  
@mixin swiper-pagination($bullet, $bullet-active) {
    @include margin($bottom: 10px);
    &-bullet {
        background: $bullet;
        opacity: 1;
        @include size(10px);
        &-active {
            background: $bullet-active;
        }
    }
}