.form {
	&__field {
		@include margin($bottom: 20px);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		&:last-of-type {
			@include margin($bottom: 0);
		}

		&-text {
			@include margin($right: 10px);
			.form__column & {
				@include margin($right: 0);
			}
			.form__column-result & {
				font-weight: 700;
			}
		}
		&-checkbox {
			input[type='checkbox'] {
				position: absolute;
				left: -999999px;
				+.checkbox {
					@include size(30px);
					background-color: $purple-lighter;
					@include margin($right: 10px);
					position: relative;
					border-radius: 5px;

					.layout-v2 & {
						border-radius: 0;
					}
				}
				&:checked {
					+.checkbox {
						&::after {
							content: '';
							position: absolute;
							@include size(11px, 18px);
							top: 50%;
							left: 10px;
							transform: translateY(-50%) rotate(45deg);
							border-bottom: 4px solid $purple;
							border-right: 4px solid $purple;

							.layout-v2 & {
								border-bottom: 4px solid $gray;
								border-right: 4px solid $gray;
							}
						}
					}
				}
			}
		}
		&-select {
			position: relative;
		}
		&-option {
			@include size(100%, auto);
			@include margin($top: 20px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			display: none;
		}

		.form__column & {
			@include margin($bottom: 20px);
		}

		&--submit {
			flex-wrap: nowrap;
			border-radius: 10px;
			overflow: hidden
		}
	}
	&__input {
		&-select {
			@include size(100%, 50px);
			background-color: $purple-lighter;
			@include padding($short: 0 45px 0 20px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 20px;
			font-weight: 700;
			color: $purple;
			cursor: pointer;
			border-radius: 10px;

			.layout-v2 & {
				color: $gray;
				border-radius: 0;
			}
			+span {
				content: '';
				position: absolute;
				pointer-events: none;
				top: 16px;
				right: 20px;
				transform: rotate(45deg);
				@include size(12px);
				border-bottom: 2px solid $gray;
				border-right: 2px solid $gray;
			}
		}
		&-time {
			@include size(110px, 50px);
			background-color: $purple-lighter;
			@include padding($short: 0 20px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 20px;
			color: $purple;
			@include margin($short: 0 10px);
			font-weight: 700;

			.layout-v2 & {
				color: $gray;
			}
			&--error {
				@include margin($top: 10px);
				width: 100%;
			}
		}

		&-text {
			@include size(auto, 50px);
			background-color: $purple-lighter;
			@include padding($short: 0 20px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 20px;
			color: $purple;
			font-weight: 700;
			flex-grow: 1;

			&::placeholder {
				color: $purple;
			}

			.layout-v2 & {
				color: $gray;
			}

			.form__column & {
				width: 100%;
			}
			.form__field--submit & {
				width: calc(100% - 50px);
			}
		}
	}
	&__label {
		@include margin($right: 20px);
		&-checkbox {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			flex-grow: 1;
			@include margin($short: 0);
		}
	}
	label {
		&.field {
			&--error {
				color: $red-light;
				font-weight: 500;
				@include margin($top: 10px);
				@include size(100%, auto);
			}
		}
	}

	&__row {
		@include size(100%, auto);
		display: flex;
		flex-wrap: wrap;
	}

	&__column {
		&-select {
			min-width: 50%;
			flex-grow: 1;
		}
		&-number {
			max-width: 90px;
			@include margin($short: 0 20px);
		}
		&-result {
			min-width: 80px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
	}
	
}