.title-columns {
	@include size(100%, auto);
	padding: 40px 30px;
	background-color: $purpleDarkV2;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__column {
		flex-basis: 50%;
		padding: 20px;
		text-decoration: none;
		@media screen and (min-width: 768px) {
			padding: 40px;
			flex-basis: 33%;
		}
		@media screen and (min-width: 1024px) {
			flex-basis: 25%;
		}

	}
	&__img {
		display: block;
		height: auto;
		border-radius: 50%;
		overflow: hidden;
		margin-bottom: 20px;
	}
}