.campaigns {
    padding: 10px 20px 20px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
    h2 {
        font-family: "Gotham", sans-serif;
        font-weight: normal;
        color: $red;
        font-size: 25px;    
        padding: 10px 0 20px;
    }
    p {
        font-family: 'Gotham',sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.2;
        padding: 0 0 20px;
        &:last-child {
            clear: both;
            padding-top: 30px;
        }
    }
    h3 {
        font-family: "Gotham", sans-serif;
        font-weight: normal;
        color: $red;
        font-size: 18px;    
        padding: 10px 0 20px;
    }
    .images {
        width: 100%;
        position: relative;
        display: block;
        div {
            width: 33.33%;
            position: relative;
            float: left;
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
            span {
                display: block;
                margin-top: 10px;
            }
        }
    }
}