.chart {
	@include size(100%, auto);
	display: none;

	.highcharts {
		&-container {
			width: 100%;
		}
		&-point {
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
		}
		&-tooltip{
			background: #ffffff;
			> span{
				// box-shadow: 5px 5px 20px 0 rgba(173,173,173,0.3);
				// background: #ffffff;
				padding: 2px 8px;
				// border-radius: 2px;
			}
		}
		&-axis {
			&-line {
				stroke-width: 1;
				stroke: #D9D9D9;
			}
		}
	}

	.tooltip {
		&-row-first {
			font-size: 12px;
			color: $purple-light;
			text-transform: uppercase;

			.layout-v2 & {
				color: $gray-light;
			}
		}
		&-row-second {
			font-size: 16px;
			color: $purple;

			.layout-v2 & {
				color: $gray;
			}
		}
	}

	&--venn {
		display: block;
		width: 100%;
		@include relative;
		@include margin($short: 20px 0 0 auto);
		max-width: 300px;
		&::after {
			content: '';
			display: block;
			@include padding($bottom: 100%)
		}
		.set {
			&__outer {
				@include size(calc(100% - 20px));
				background-color: $green;
				border-radius: 50%;
				box-shadow: 0 0 0 10px $green;
				@include margin($short: 10px);
				.layout-v2 & {
					background-color: $bordeauxV2;
					box-shadow: 0 0 0 10px $bordeauxV2;
				}
				&-value {
					@include absolute(left 50%);
					top: 0;
					transform: translateX(-50%);
					font-size: 16px;
					font-weight: 500;
					color: #ffffff;
					&.out {
						color: $gray;
					}
				}
			}
			&__inner {
				@include absolute(bottom 10px left 50%);
				transform: translateX(-50%);
				background-color: $orange;
				border-radius: 50%;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				.layout-v2 & {
					background-color: $orangeV2;
				}
				&-value {
					font-size: 16px;
					font-weight: 500;
					color: #ffffff;
				}
			}
		}
	}
	&__container {
		@include size(100%);
		@include absolute(top 50% left 50%);
		transform: translate(-50%, -50%);
	}
}