.wrapper-inner {
	display: flex;
	flex-wrap: wrap;
	background-color: #ffffff;
	padding: 0px 30px 0px 30px;
	// border-radius: 20px;

	.layout-v2 & {
		border-radius: 0;
	}
}

.campain {
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
}

strong {
	font-weight: bold;
}

.banner {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: row;
	margin-bottom: 20px;
}

.info p {
	margin-bottom: 60px;
	line-height: 22px;
}

.box-program {
	max-width: 990px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;

	div {
		display: flex;
		max-width: 100%;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;

		ul {
			width: 230px;
			max-width: 230px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			flex-flow: row wrap;

			img {
				margin-bottom: 30px;
				max-height: 170px;
			}

			li {
				width: 100%;
				max-width: 100%;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				button {
					margin-top: 30px;
					margin-bottom: 30px;
					background-color: $red-500;
					color: #ffffff;
					border: none;
					// border-radius: 5px;
					height: 30px;
					width: 125px;

					.layout-v2 & {
						background-color: $red;
					}
				}

				&:nth-child(3) {
					margin-bottom: 25px;
				}

				h3,
				h5 {
					// color: $purple;

					.layout-v2 & {
						color: $gray;
					}
				}
			}
		}
	}
}

.info2 {
	display: flex;
	justify-content: center;
	flex: 1 100%;

	p {
 		display: flex;
 		justify-content: center;
 		margin-bottom: 35px;
 		font-size: 14px;
 	}
}

.steps {
 	display: flex;
 	flex: 1 100%;
 	flex-flow: row wrap;
 	justify-content: flex-start;
 	margin-bottom: 50px;

 	ul {
 		display: flex;
 		flex-flow: row wrap;
 		margin-bottom: 25px;

 		li {
 			display: flex;
 			width: 100%;
 			align-items: center;

 			span {
 				display: flex;
 				background-color: $red-500;
 				color: #ffffff;
 				border: 0;
 				border-radius: 50%;
 				height: 40px;
 				width: 40px;
 				margin: 20px 20px 25px 0px;
 				font-size: 28px;
 				align-items: center;
 				justify-content: center;

				.layout-v2 & {
					background-color: $red;
				}
 			}
 		}
 	}
}

.campain h2 {
 	display: flex;
 	// color: $purple;
 	font-size: 31px;
 	margin-top: 30px;
 	margin-bottom: 30px;
 	font-weight: 800;

	.layout-v2 & {
		color: $red;
	}
}

.steps {
 	h2 {
 		display: flex;
 		color: $black-800;
 		font-size: 25px;
 		margin-top: 30px;
 		margin-bottom: 30px;
 		font-weight: bold;

		.layout-v2 & {
			color: $red;
		}
 	}

 	ul {
 		li {
 			a {
 				color: $red-500;
 				text-decoration: none;

				.layout-v2 & {
					color: $red;
				}
 			}

 			h4 {
 				color: $red-500;
				text-transform: uppercase;
 				font-weight: bold;

				.layout-v2 & {
					color: $red;
				}
 			}
 		}
 	}
}

 .btncta {
 	margin: 0 auto;

 	.btncta__btn {
 		margin-bottom: 30px;
 		color: #ed1c24;
 		border: none;
 		border-radius: 80px;
 		height: 40px;
 		width: 200px;
 	}

 }

 .program-v2 {
 	@include size(100%, auto);
 	padding: 40px 30px;
 	background-color: $white;

 	.title,
 	.text {
 		text-transform: uppercase;
 	}

 	&__container {
 		margin-bottom: 60px;

 		@media screen and (min-width: 1024px) {
 			padding: 0 100px;
 		}

 	}

 	&__row {
 		display: flex;
 		flex-wrap: wrap;
 		justify-content: center;
 		margin-bottom: 30px;

 		&:last-of-type {
 			margin-bottom: 0;
 		}

 		&--column {
 			flex-direction: column;
 		}

 		>.program-v2__table {
 			&:nth-of-type(2) {
 				.program-v2__column {
 					background-color: #f26522;
 				}
 			}

 			&:nth-of-type(3) {
 				.program-v2__column {
 					background-color: #a51144;
 				}
 			}

 			&:nth-of-type(4) {
 				.program-v2__column {
 					background-color: #6f1050;
 				}
 			}

 			&:last-of-type {
 				.program-v2__column {
 					background-color: #470048;
 				}
 			}
 		}
 	}

 	&__img {
 		display: block;
 		height: auto;
 		margin: 5px;
 		width: 100%;
 		max-width: 115px;
 	}

 	&__logo {
 		img {
 			display: block;
 			width: 100%;
 			height: auto;
 			max-width: 744px;
 		}
 	}

 	&__column {
 		display: flex;
 		flex-wrap: wrap;

 		.program-v2__table & {
 			background-color: #f68b1f;
 			margin: 5px;
 			align-items: center;
 			justify-content: center;
 			padding: 20px;

 			&:first-of-type {
 				.text {
 					font-size: 18px;
 					font-weight: 700;

 					@media screen and (min-width: 1024px) {
 						font-size: 24px;
 					}

 					span {
 						font-weight: 400;
 					}
 				}
 			}

 			&:nth-of-type(2) {
 				.text {
 					font-size: 18px;
 					line-height: 1.1;

 					@media screen and (min-width: 1024px) {
 						font-size: 24px;
 					}
 				}
 			}
 		}
 	}

 	&__table {
 		margin-bottom: 0;

 		&-item {
 			flex-basis: calc(33.33% - (40px / 3));
 			display: flex;
 			flex-direction: column;
 			align-items: center;
 			margin: 0 20px 0 0;

 			&:last-of-type {
 				margin: 0;
 			}

 			img {
 				border-radius: 50%;
 				margin-bottom: 10px;
 			}
 		}

 		.program-v2__column {
 			flex-basis: calc(60% - 45px);
 			min-height: 180px;

 			&:first-of-type {
 				flex-basis: 40px;
 				@include relative;

 				.text {
 					@include absolute(top 50% left 50%);
 					transform: translate(-50%, -50%) rotate(-90deg);
 					width: 150px;
 				}
 			}

 			&:nth-of-type(2) {
 				flex-basis: calc(40% - 45px);
 				flex-grow: 1;
 			}

 			&:last-of-type {
 				flex-grow: 1;
 			}

 			.text {
 				text-align: center;
 				text-transform: uppercase;
 				font-size: 12px;
 				line-height: 1.1;

 				@media screen and (min-width: 1024px) {
 					font-size: 16px;
 				}
 			}
 		}
 	}
 }
