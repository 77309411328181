.intro {
    position: relative;
    background-color: $red-500;
    @include padding($short: 40px 30px);

    &__background-image {
        @include absolute(top 0 bottom 0px left 0 right 0);
        mix-blend-mode: multiply;
        background-size: cover;
    }

    &__logo-inverted {
        display: block;
        max-width: 200px;
        margin: 0 auto 16px;
        position: relative;
        z-index: 1;
    }

    &__title {
        position: relative;
        z-index: 1;
        margin-bottom: 8px;
        font-size: 31px;
		font-weight: 500;
		line-height: 31px;
		color: $white;
        text-align: center;
    }

    &__text {
        position: relative;
        z-index: 1;
        margin-bottom: 16px;
        font-size: 16px;
		line-height: 21.82px;
		color: $white;
        text-align: center;
    }

    &__icons {
        position: relative;
        z-index: 1;
        display: block;
        max-width: 200px;
        margin-inline: auto;
    }
}
