.slider {
	background: #ffffff;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	position: relative;
	// box-shadow: $box-shadow;
	// border-radius: 20px;
	overflow: hidden;

	.user-data {
		position: relative;
		width: 27%;
		text-align: center;
		padding: 30px 20px;
		display: block;
		float: left;

		.user {
			display: inline-block;
			background: #e9e9e9;
			width: 70px;
			height: 70px;
			border-radius: 50px;
			text-align: center;
			margin-bottom: 20px;

			&:after {
				content: '\f007';
				font-family: "Font Awesome 5 Free";
				display: block;
				font-size: 40px;
				line-height: 70px;
				color: #ddddde;
			}
		}

		.text {
			font-size: 14px;
			line-height: 20px;

			span {
				color: $bordeaux;
				font-size: 20px;
				font-family: $nunito;
				font-weight: 500;
				margin-top: 5px;
				margin-bottom: 20px;
				display: inline-block;
			}
		}

		.btns {
			a {
				font-family: $nunito;
				font-weight: 700;
				background: $red-500;
				text-transform: uppercase;
				border: 0;
				// border-radius: 50px;
				color: #fff;
				font-size: 16px;
				padding: 16px 45px;
				cursor: pointer;
				text-decoration: none;
				display: inline-block;
				margin: 0 5px;
				line-height: 18px;
			}
		}
	}

	.slide {
		.slide-container {
			height: 100%;

			img {
				height: 100%;
			}
		}
	}
}
