.footer {
	background: #5a5a5a;
	position: absolute;
	width: 100%;
	padding: 26px;
	margin-top: 15px;
	bottom: -115px;
	left: 0;
	.layout-v2 & {
		background-color: $purpleV2;
		position: static;
	}

	.footer-wrapper {
		position: relative;
		width: 100%;
		display: block;
		max-width: 1140px;
		margin: 0 auto;
		height: 100%;

		.layout-v2 & {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}

		ul {
			position: relative;
			width: 50%;
			display: block;
			margin: 0 auto;
			height: 100%;
			float: left;

			li {
				position: relative;
				display: inline-block;
				margin: 0 20px;

				a {
					color: #ffffff;
					font-size: 12px;
					text-decoration: none;
				}
			}

			&:last-child {
				text-align: right;
			}
		}
	}
	&-list {
		> * {
			color: $white;
			font-size: 14px;
			line-height: 16px;
		}

	}

}